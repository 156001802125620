import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { requirementsActions } from '@redux/reducers/common/requirements/requirementsReducer';
import {
  GetRequirementsRequestPayload,
  GetRequirementsSuccessPayload,
} from '@redux/types/common/requirements/requirementsTypes';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export const getRequirementsList = (params: GetRequirementsRequestPayload) => {
  return $http.get<GetRequirementsSuccessPayload>(`/acquisitions${getQueriesAsSearch(params)}`);
};

export function* getRequirementsSaga(action: PayloadAction<GetRequirementsRequestPayload>) {
  try {
    yield put(requirementsActions.setRequirementsLoading(true));
    const { data } = yield call(() => getRequirementsList(action.payload));

    yield put(requirementsActions.getRequirementsSuccess(data));
  } catch (e) {
    yield put(requirementsActions.getRequirementsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
