import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { savedViewsRequirementInsightsActions } from '@redux/reducers/saved_views/insights/savedViewRequirementInsightsReducer';
import { savedViewsInsightsRequirementsSelectors } from '@redux/selectors/saved_views/insights/savedViewRequirementInsightsSelector';
import {
  ManageSavedViewRequestPayload,
  PinUnpinSavedViewRequestPayload,
  SavedViewState,
  UpdateSavedViewRequestPayload,
} from '@redux/types/saved-views/common/savedViewsCommonTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

const getSavedViewRequirements = () => {
  return $http.get(`/saved-views/requirements-insights`);
};

const getSavedViewRequirementById = (id: Id) => {
  return $http.get(`/saved-views/requirements-insights/${id}`);
};

const updatePinnedSavedViews = ({ userId, pinnedIds }: UpdateSavedViewRequestPayload, cancelToken: CancelToken) => {
  return $http.put(
    `/users/${userId}/update/pinned_views_requirement_insights`,
    {
      pinned_views_requirement_insights: pinnedIds,
    },
    { cancelToken }
  );
};

const deleteSavedView = (id: Id) => {
  return $http.delete(`/saved-views/${id}`);
};

const editSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.put(`/saved-views/${data.id}`, data);
};

const createSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.post(`/saved-views/requirements-insights`, data);
};

export function* getInsightsRequirementsSaga() {
  try {
    yield put(savedViewsRequirementInsightsActions.setListLoading(true));

    const { data } = yield call(getSavedViewRequirements);
    yield put(savedViewsRequirementInsightsActions.getListSuccess(data.data));
  } catch (e) {
    const errorMessage = getDefaultError(e?.message);

    showNotification(errorMessage, 'error');
    yield put(savedViewsRequirementInsightsActions.getListFailure(errorMessage));
  }
}

export function* getSavedViewRequirementByIdSaga(action: PayloadAction<Id>) {
  try {
    yield put(savedViewsRequirementInsightsActions.setUnpinnedViewLoading(true));
    const { data } = yield call(() => getSavedViewRequirementById(action.payload));

    yield put(savedViewsRequirementInsightsActions.getUnpinnedViewSuccess(data));
  } catch (e) {
    yield put(savedViewsRequirementInsightsActions.getUnpinnedViewFailure(getDefaultError(e?.message)));
  }
}

export function* updatePinnedInsightsRequirementsSavedViewsSaga(action: PayloadAction<UpdateSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsRequirementInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(savedViewsRequirementInsightsActions.updateViewsSuccess());
  } catch (e) {
    yield put(savedViewsRequirementInsightsActions.updateViewsFailure(getDefaultError(e?.message)));
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsInsightsRequirementsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* pinInsightsRequirementsSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsRequirementInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewsRequirementInsightsActions.pinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsInsightsRequirementsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* unpinInsightsRequirementsSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewsRequirementInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewsRequirementInsightsActions.unpinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewsInsightsRequirementsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* deleteSavedViewInsightsRequirementsSaga(action: PayloadAction<Id>) {
  try {
    yield call(() => deleteSavedView(action.payload));

    yield put(configActions.deleteSavedViewInsightsRequirementsSuccess(action.payload));
    yield put(savedViewsRequirementInsightsActions.deleteViewSuccess(action.payload));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}

export function* editSavedViewInsightsRequirementsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => editSavedView(action.payload));

    yield put(configActions.editSavedViewInsightsRequirementsSuccess(data));
    yield put(savedViewsRequirementInsightsActions.editViewSuccess(data));
  } catch (e) {
    showNotification(
      getDefaultError('Sorry, we could not update your view. Please contact us for assistance.'),
      'warning'
    );
  }
}

export function* createSavedViewInsightsRequirementsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => createSavedView(action.payload));

    yield put(savedViewsRequirementInsightsActions.createViewSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}
