import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const saleTransactionsState = (state: RootState) => state.insights.salesTransactions;

const getState = createSelector(saleTransactionsState, (state) => state);

export const saleInsightsSelectors = {
  getState,
};
