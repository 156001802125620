import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { UsersFilteringParams, UsersResponse } from '@apis/usersApi.types';
import { getHTTPClient } from '@core/http-client';
import { usersActions } from '@redux/reducers/common/users/usersReducer';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export const getUsersList = (params: UsersFilteringParams) => {
  return $http.get<UsersResponse>(`/users${getQueriesAsSearch(params)}`);
};

export function* getUsersSaga(action: PayloadAction<UsersFilteringParams>) {
  try {
    yield put(usersActions.setUsersLoading(true));
    const { data } = yield call(() => getUsersList(action.payload));

    yield put(usersActions.getUserSuccess(data));
  } catch (e) {
    yield put(usersActions.getUsersFailure(getDefaultError(e?.response?.data?.message)));
  }
}
