import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { insightsRequirementsActions } from '@redux/reducers/insights/requirements/requirementsInsightsReducer';
import { insightsRequirementsSelectors } from '@redux/selectors/insights/insightsRequirements';
import {
  GetRequirementInsightsRequestPayload,
  GetRequirementInsightsResponse,
  RequirementInsightsState,
} from '@redux/types/insights/requirement/requirementInsightsTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

const getRequirementsStatistic = (params: GetRequirementInsightsRequestPayload, cancelToken: CancelToken) => {
  return $http.get<GetRequirementInsightsResponse>(`/acquisitions/statistics${getQueriesAsSearch(params)}`, {
    cancelToken,
  });
};

export function* getInsightsRequirementsSaga(action: PayloadAction<GetRequirementInsightsRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(insightsRequirementsActions.setStatisticLoading(true));

    const { data } = yield call(() => getRequirementsStatistic(action.payload, source.token));

    yield put(insightsRequirementsActions.getStatisticSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message || e?.message);
    showNotification(error, 'error');
    yield put(insightsRequirementsActions.getStatisticFailure(error));
  } finally {
    const { loading }: RequirementInsightsState = yield select(insightsRequirementsSelectors.getState);

    if (loading) {
      source.cancel();
    }
  }
}
