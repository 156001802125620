import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const mainState = (state: RootState) => state.insights.acquisitions;

const getState = createSelector(mainState, (state) => state);

export const societyAcquisitionInsightsSelectors = {
  getState,
};
