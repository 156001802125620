import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { companiesActions } from '@redux/reducers/common/companies/companiesReducer';
import { GetCompaniesRequestPayload, GetCompaniesSuccessPayload } from '@redux/types/common/companies/companiesTypes';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export const getCompaniesList = (params: GetCompaniesRequestPayload) => {
  return $http.get<GetCompaniesSuccessPayload>(`/companies/ac${getQueriesAsSearch(params)}`);
};

export function* getCompaniesSaga(action: PayloadAction<GetCompaniesRequestPayload>) {
  try {
    yield put(companiesActions.setCompaniesLoading(true));
    const { data } = yield call(() => getCompaniesList(action.payload));

    yield put(companiesActions.getCompaniesSuccess(data));
  } catch (e) {
    yield put(companiesActions.getCompaniesFailure(getDefaultError(e?.response?.data?.message)));
  }
}
