import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const savedViewsContactsState = (state: RootState) => state.savedViews.contacts;

const getState = createSelector(savedViewsContactsState, (state) => state);
const unpinnedSavedView = createSelector(savedViewsContactsState, (state) => state.unpinnedActiveSavedView);

export const savedViewsContactsSelectors = {
  getState,
  unpinnedSavedView,
};
