import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';
import { rootSaga } from './sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = {
  ...createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware, thunk)) //TODO temporary fix, going to resolve in future
  ),
  runSaga: sagaMiddleware.run,
};
store.runSaga(rootSaga);

export default store;
