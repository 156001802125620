import { all, takeLatest } from 'redux-saga/effects';

import { campaignsActions } from '@redux/reducers/campaigns/campaigns-list/campaignsListReducer';
import { manageMultiPropertyCampaignActions } from '@redux/reducers/campaigns/manage-multi-property-campaign/manageMultiPropertyCampaignReducer';
import { manageSinglePropertyCampaignActions } from '@redux/reducers/campaigns/manage-single-property-campaign/manageSinglePropertyCampaignReducer';
import { campaignRecipientsActions } from '@redux/reducers/campaigns/recipients/campaignRecipientsReducer';
import { campaignResultsActions } from '@redux/reducers/campaigns/results/campaignResultsReducer';
import {
  createMultiPropertyCampaignSaga,
  deleteMultiEmailCampaignSaga,
  getMultiPropertyCreatingDataSaga,
  getMultiPropertyRecipientsSaga,
  scheduleMultiPropertyCampaignSaga,
  sendTestMultiPropertyEmailSaga,
  unScheduleMultiPropertyCampaignSaga,
} from '@redux/sagas/campaigns/manage-multi-property-campaign/manageMultiPropertyCampaignSaga';

import { getCampaignsListSaga } from './campaigns-list/campaignsListSaga';
import {
  createSinglePropertyCampaignSaga,
  deleteEmailCampaignSaga,
  getRecipientsSaga,
  getSinglePropertyCampaignSaga,
  scheduleSinglePropertyCampaignSaga,
  sendTestEmailSaga,
  unScheduleSinglePropertyCampaignSaga,
} from './manage-single-property-campaign/manageSinglePropertyCampaignSaga';
import { getRecipientsDrawerSaga, getUpdatedRecipientsSaga } from './recipients/campaignRecipientsSaga';
import { getRealtimeResultsSaga, getRecipientsResultsSaga, getResultsSaga } from './results/campaignResultsSaga';

function* campaignsSaga() {
  yield all([takeLatest(campaignsActions.getCampaignsRequest.type, getCampaignsListSaga)]);
  yield all([
    takeLatest(manageSinglePropertyCampaignActions.getEmailTemplateRequest.type, getSinglePropertyCampaignSaga),
  ]);
  yield all([takeLatest(manageSinglePropertyCampaignActions.getRecipientsRequest.type, getRecipientsSaga)]);
  yield all([
    takeLatest(manageSinglePropertyCampaignActions.createEmailCampaignRequest.type, createSinglePropertyCampaignSaga),
  ]);
  yield all([takeLatest(manageSinglePropertyCampaignActions.sendTestEmailRequest.type, sendTestEmailSaga)]);
  yield all([
    takeLatest(manageSinglePropertyCampaignActions.unScheduleRequest.type, unScheduleSinglePropertyCampaignSaga),
  ]);
  yield all([takeLatest(manageSinglePropertyCampaignActions.scheduleRequest.type, scheduleSinglePropertyCampaignSaga)]);
  yield all([takeLatest(manageSinglePropertyCampaignActions.deleteRequest.type, deleteEmailCampaignSaga)]);

  yield all([takeLatest(campaignRecipientsActions.getRecipientsRequest.type, getRecipientsDrawerSaga)]);
  yield all([takeLatest(campaignRecipientsActions.getUpdatedRecipientsRequest.type, getUpdatedRecipientsSaga)]);

  yield all([takeLatest(manageMultiPropertyCampaignActions.getTemplateRequest.type, getMultiPropertyCreatingDataSaga)]);

  yield all([takeLatest(manageMultiPropertyCampaignActions.getRecipientsRequest.type, getMultiPropertyRecipientsSaga)]);
  yield all([
    takeLatest(manageMultiPropertyCampaignActions.createEmailCampaignRequest.type, createMultiPropertyCampaignSaga),
  ]);
  yield all([takeLatest(manageMultiPropertyCampaignActions.sendTestEmailRequest.type, sendTestMultiPropertyEmailSaga)]);
  yield all([
    takeLatest(manageMultiPropertyCampaignActions.unScheduleRequest.type, unScheduleMultiPropertyCampaignSaga),
  ]);
  yield all([takeLatest(manageMultiPropertyCampaignActions.scheduleRequest.type, scheduleMultiPropertyCampaignSaga)]);
  yield all([takeLatest(manageMultiPropertyCampaignActions.deleteRequest.type, deleteMultiEmailCampaignSaga)]);

  yield all([takeLatest(campaignResultsActions.getResultsRequest.type, getResultsSaga)]);
  yield all([takeLatest(campaignResultsActions.getRecipientsResultsRequest.type, getRecipientsResultsSaga)]);
  yield all([takeLatest(campaignResultsActions.getRealtimeResultsRequest.type, getRealtimeResultsSaga)]);
}

export default campaignsSaga;
