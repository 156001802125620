import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { societySegmentActions } from '@redux/reducers/common/society-segments/societySegmentsReducer';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getSocietySegmentsSaga() {
  try {
    yield put(societySegmentActions.setSocietySegmentsLoading());
    const { data } = yield call(() => $http.get(`segments/insights`));

    yield put(societySegmentActions.getSocietySegmentsSuccess(data.data));
  } catch (e) {
    yield put(societySegmentActions.getSocietySegmentsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
