import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { areaTeamsActions } from '@redux/reducers/common/area-teams/areaTeamsReducer';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

const getAreaTeams = () => {
  return $http.get(`segments/team`);
};

export function* getAreaTeamsSaga() {
  try {
    yield put(areaTeamsActions.setAreaTeamsLoading(true));
    const { data } = yield call(getAreaTeams);

    yield put(areaTeamsActions.getAreaTeamsSuccess(data.data));
  } catch (e) {
    yield put(areaTeamsActions.getAreaTeamsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
