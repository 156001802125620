import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import { mapboxActions } from '@redux/reducers/common/mapbox-locations/mapboxLocationsReducer';
import { GetMapboxLocationsRequestPayload } from '@redux/types/common/mapbox-locations/mapboxLocationsTypes';
import { getDefaultError } from '@shared/utils/common';

export const getMapboxLocationsList = ({ search, key }: GetMapboxLocationsRequestPayload) => {
  return axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${key}&proximity=-0.1522502,51.4977568`
  );
};

export function* getMapboxLocationsSaga(action: PayloadAction<GetMapboxLocationsRequestPayload>) {
  try {
    yield put(mapboxActions.setMapboxLoading(true));
    const { data } = yield call(() => getMapboxLocationsList(action.payload));

    yield put(mapboxActions.getMapboxLocationsSuccess(data?.features));
  } catch (e) {
    yield put(mapboxActions.getMapboxLocationsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
