import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { manageMultiPropertyCampaignActions } from '@redux/reducers/campaigns/manage-multi-property-campaign/manageMultiPropertyCampaignReducer';
import { campaignRecipientsActions } from '@redux/reducers/campaigns/recipients/campaignRecipientsReducer';
import {
  CreateMultiPropertyCampaignRequestPayload,
  ScheduleMultiPropertyEmailCampaignPayload,
  SendTestMultiPropertyEmailCampaignPayload,
} from '@redux/types/campaigns/manageCampaignMultiPropertyTypes';
import {
  DeleteEmailCampaignPayload,
  GetCampaignRecipientsRequestPayload,
  UnScheduleEmailCampaignPayload,
} from '@redux/types/campaigns/manageCampaignTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getMultiPropertyCreatingDataSaga(action: PayloadAction<Id | undefined>) {
  try {
    yield put(manageMultiPropertyCampaignActions.setTemplateLoading(true));

    if (action.payload) {
      const { data } = yield call(() => $http.get(`/emails-campaigns/multi-property/${action.payload}`));

      yield put(manageMultiPropertyCampaignActions.getTemplateSuccess(data));
    } else {
      const { data } = yield call(() => $http.get(`/emails-campaigns/multi-property/creating`));

      yield put(manageMultiPropertyCampaignActions.getTemplateSuccess(data.data));
    }
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);

    yield put(manageMultiPropertyCampaignActions.getTemplateFailure(error));
  }
}

export function* getMultiPropertyRecipientsSaga(action: PayloadAction<GetCampaignRecipientsRequestPayload>) {
  const source = getCancelRequestSource();
  try {
    yield put(manageMultiPropertyCampaignActions.setRecipientsLoading(true));
    yield put(campaignRecipientsActions.setRecipientsLoading(true));

    action.payload.limit = 1;

    const { data } = yield call(() =>
      $http.post(`/emails-campaigns/contacts`, action.payload, { cancelToken: source.token })
    );

    yield put(manageMultiPropertyCampaignActions.getRecipientsSuccess(data));
    yield put(campaignRecipientsActions.getRecipientsSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);

    yield put(manageMultiPropertyCampaignActions.getRecipientsFailure(error));
    yield put(campaignRecipientsActions.getRecipientsFailure(error));

    showNotification(error, 'error');
  } finally {
    source.cancel();
  }
}

export function* createMultiPropertyCampaignSaga(action: PayloadAction<CreateMultiPropertyCampaignRequestPayload>) {
  try {
    yield put(manageMultiPropertyCampaignActions.setCreatingLoading(true));

    yield call(() => $http.post(`/emails-campaigns/multi-property/creating`, action.payload.data));

    yield put(manageMultiPropertyCampaignActions.createEmailCampaignSuccess());

    if (action.payload.callback) {
      action.payload.callback();
    }
  } catch (error) {
    if (error?.response?.status == 422) {
      error.errorMessages = {};
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((item: string) => {
          if (Array.isArray(error?.response?.data?.errors[item])) {
            error.errorMessages[item] = error?.response?.data?.errors[item][0];
          } else {
            error.errorMessages[item] = error?.response?.data?.errors[item];
          }
        });

        yield put(manageMultiPropertyCampaignActions.createEmailCampaignFailure(error.errorMessages));
      }
    } else {
      showNotification(getDefaultError(error?.response?.data?.message), 'error');
      yield put(manageMultiPropertyCampaignActions.createEmailCampaignFailure({}));
    }
  }
}

export function* sendTestMultiPropertyEmailSaga(action: PayloadAction<SendTestMultiPropertyEmailCampaignPayload>) {
  try {
    yield put(manageMultiPropertyCampaignActions.setSendTestEmailLoading(true));

    yield call(() => $http.post(`/emails-campaigns/multi-property/send-test-email`, action.payload));

    yield put(manageMultiPropertyCampaignActions.sendTestEmailSuccess());
  } catch (e) {
    yield put(
      manageMultiPropertyCampaignActions.sendTestEmailFailure(getDefaultError(e?.response?.data?.message || e?.message))
    );
  }
}

export function* unScheduleMultiPropertyCampaignSaga(action: PayloadAction<UnScheduleEmailCampaignPayload>) {
  try {
    yield put(manageMultiPropertyCampaignActions.setUnScheduleLoading(true));

    yield call(() => $http.put(`/emails-campaigns/${action.payload.id}/unschedule`));

    yield put(manageMultiPropertyCampaignActions.unScheduleSuccess());

    action.payload.callback();
  } catch (e) {
    yield put(manageMultiPropertyCampaignActions.unScheduleFailure(getDefaultError(e?.response?.data?.message)));
  }
}

export function* scheduleMultiPropertyCampaignSaga(action: PayloadAction<ScheduleMultiPropertyEmailCampaignPayload>) {
  try {
    yield put(manageMultiPropertyCampaignActions.setScheduleLoading(true));

    yield call(() => $http.put(`emails-campaigns/multi-property/${action.payload.id}/schedule`, action.payload.data));

    yield put(manageMultiPropertyCampaignActions.scheduleSuccess());
    action.payload.callback();
  } catch (e) {
    if (e?.response?.status == 422) {
      const errorMessages: ItemString = {};
      if (e?.response?.data?.errors) {
        Object.keys(e?.response?.data?.errors).forEach((item: string) => {
          if (Array.isArray(e?.response?.data?.errors[item])) {
            errorMessages[item] = e?.response?.data?.errors[item][0];
          } else {
            errorMessages[item] = e?.response?.data?.errors[item];
          }
        });
        yield put(manageMultiPropertyCampaignActions.scheduleFailure(errorMessages));
      }
    } else {
      showNotification(getDefaultError(e?.response?.data?.message), 'error');

      yield put(manageMultiPropertyCampaignActions.scheduleFailure({}));
    }
  }
}

export function* deleteMultiEmailCampaignSaga(action: PayloadAction<DeleteEmailCampaignPayload>) {
  try {
    yield put(manageMultiPropertyCampaignActions.setDeleteLoading(true));

    yield call(() => $http.delete(`/emails-campaigns/${action.payload.id}`));

    yield put(manageMultiPropertyCampaignActions.deleteSuccess());

    action.payload.callback();
  } catch (e) {
    yield put(manageMultiPropertyCampaignActions.deleteFailure(getDefaultError(e?.response?.data?.message)));
  }
}
