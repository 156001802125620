import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient, yieldFailureResponse, yieldSuccessResponse } from '@core/http-client';
import { viewingsInsightsActions } from '@redux/reducers/insights/viewings-insights/viewingsInsightsReducer';
import { viewingsInsightsSelectors } from '@redux/selectors/insights/viewings';
import {
  GetViewingsInsightsRequestPayload,
  GetViewingsInsightsResponse,
  ViewingsInsightsState,
} from '@redux/types/insights/viewings-insights/viewingsInsightsTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getViewingsInsightsSaga(action: PayloadAction<GetViewingsInsightsRequestPayload>) {
  const source = getCancelRequestSource();

  yield put(viewingsInsightsActions.setListLoading(true));

  const { error, response }: GetViewingsInsightsResponse = yield call(() =>
    $http
      .get(`/viewings/statistics${getQueriesAsSearch(action.payload)}`, {
        cancelToken: source.token,
      })
      .then(yieldSuccessResponse)
      .catch(yieldFailureResponse)
  );

  if (response) {
    yield put(viewingsInsightsActions.getListSuccess({ data: response.data.data, meta: response.data.meta }));
  } else if (error) {
    const errorMessage = getDefaultError(error.response?.data?.message || error.message);

    // Set the societies invalid flag
    if (error.response?.status === 403) {
      yield put(viewingsInsightsActions.setListSocietiesInvalid(true));
    }

    // Show error notification
    showNotification(errorMessage, 'error');

    // Set the message / errors list
    yield put(viewingsInsightsActions.getListFailure(errorMessage));
  }

  const { loading }: ViewingsInsightsState = yield select(viewingsInsightsSelectors.getState);
  if (loading) {
    source.cancel();
  }
}
