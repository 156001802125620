import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const savedViewsCompaniesState = (state: RootState) => state.savedViews.companies;

const getState = createSelector(savedViewsCompaniesState, (state) => state);
const unpinnedSavedView = createSelector(savedViewsCompaniesState, (state) => state.unpinnedActiveSavedView);

export const savedViewsCompaniesSelectors = {
  getState,
  unpinnedSavedView,
};
