import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { savedViewLettingInsightsActions } from '@redux/reducers/saved_views/insights/savedViewLettingInsightsReducer';
import { savedViewLettingInsightsSelectors } from '@redux/selectors/saved_views/insights/savedViewLettingInsightsSelector';
import {
  ManageSavedViewRequestPayload,
  PinUnpinSavedViewRequestPayload,
  SavedViewState,
  UpdateSavedViewRequestPayload,
} from '@redux/types/saved-views/common/savedViewsCommonTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

const getLettingInsights = () => {
  return $http.get(`/saved-views/letting-transactions-insights`);
};

const getLettingInsightsById = (id: Id) => {
  return $http.get(`/saved-views/letting-transactions-insights/${id}`);
};

const updatePinnedLettingInsightsSavedViews = (
  { userId, pinnedIds }: UpdateSavedViewRequestPayload,
  cancelToken: CancelToken
) => {
  return $http.put(
    `/users/${userId}/update/pinned_views_letting_transaction_insights`,
    {
      pinned_views_letting_transaction_insights: pinnedIds,
    },
    { cancelToken }
  );
};

const deleteSavedView = (id: Id) => {
  return $http.delete(`/saved-views/${id}`);
};

const editSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.put(`/saved-views/${data.id}`, data);
};

const createSavedView = (data: ManageSavedViewRequestPayload) => {
  return $http.post(`/saved-views/letting-transactions-insights`, data);
};

export function* getLettingInsightsSaga() {
  try {
    yield put(savedViewLettingInsightsActions.setListLoading(true));

    const { data } = yield call(getLettingInsights);
    yield put(savedViewLettingInsightsActions.getListSuccess(data.data));
  } catch (e) {
    const errorMessage = getDefaultError(e?.message);

    showNotification(errorMessage, 'error');
    yield put(savedViewLettingInsightsActions.getListFailure(errorMessage));
  }
}

export function* getLettingInsightsByIdSaga(action: PayloadAction<Id>) {
  try {
    yield put(savedViewLettingInsightsActions.setUnpinnedViewLoading(true));

    const { data } = yield call(() => getLettingInsightsById(action.payload));
    yield put(savedViewLettingInsightsActions.getUnpinnedViewSuccess(data));
  } catch (e) {
    yield put(savedViewLettingInsightsActions.getUnpinnedViewFailure(getDefaultError(e?.message)));
  }
}

export function* updatePinnedLettingInsightsSavedViewsSaga(action: PayloadAction<UpdateSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewLettingInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedLettingInsightsSavedViews(action.payload, source.token));

    yield put(configActions.setUser(data));
    yield put(savedViewLettingInsightsActions.updateViewsSuccess());
  } catch (e) {
    yield put(savedViewLettingInsightsActions.updateViewsFailure(getDefaultError(e?.message)));
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewLettingInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* pinLettingInsightsSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewLettingInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedLettingInsightsSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewLettingInsightsActions.pinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewLettingInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* unpinLettingInsightsSavedViewsSaga(action: PayloadAction<PinUnpinSavedViewRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(savedViewLettingInsightsActions.setUpdateViewsRequestLoading(true));

    const { data } = yield call(() => updatePinnedLettingInsightsSavedViews(action.payload, source.token));
    yield put(configActions.setUser(data));
    yield put(
      savedViewLettingInsightsActions.unpinViewSuccess({
        view: action.payload.view,
        isActiveView: action.payload.isActiveView,
      })
    );
  } finally {
    const { updateLoading }: SavedViewState = yield select(savedViewLettingInsightsSelectors.getState);

    if (updateLoading) {
      source.cancel();
    }
  }
}

export function* deleteSavedViewLettingInsightsSaga(action: PayloadAction<Id>) {
  try {
    yield call(() => deleteSavedView(action.payload));

    yield put(configActions.deleteSavedViewLettingInsightsSuccess(action.payload));
    yield put(savedViewLettingInsightsActions.deleteViewSuccess(action.payload));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}

export function* editSavedViewLettingInsightsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => editSavedView(action.payload));

    yield put(configActions.editSavedViewLettingInsightsSuccess(data));
    yield put(savedViewLettingInsightsActions.editViewSuccess(data));
  } catch (e) {
    showNotification(
      getDefaultError('Sorry, we could not update your view. Please contact us for assistance.'),
      'error'
    );
  }
}

export function* createSavedViewLettingInsightsSaga(action: PayloadAction<ManageSavedViewRequestPayload>) {
  try {
    const { data } = yield call(() => createSavedView(action.payload));

    yield put(savedViewLettingInsightsActions.createViewSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
  }
}
