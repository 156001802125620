import { all, takeLatest } from 'redux-saga/effects';

import { companyManageActions } from '@redux/reducers/crm/companies/manage/companiesManageReducer';
import { contactManageActions } from '@redux/reducers/crm/contacts/manage/contactManageReducer';

import { createCompanySaga, deleteCompanySaga } from './companies/manageCompanySaga';
import {
  createContactsSaga,
  deleteContactSaga,
  getContactDetailsSaga,
  updateContactDetailsSaga,
} from './contacts/manageContactSaga';

function* crmSaga() {
  yield all([takeLatest(contactManageActions.createContactRequest.type, createContactsSaga)]);
  yield all([takeLatest(contactManageActions.getDetailsRequest.type, getContactDetailsSaga)]);
  yield all([takeLatest(contactManageActions.updateDetailsRequest.type, updateContactDetailsSaga)]);
  yield all([takeLatest(contactManageActions.deleteContactRequest.type, deleteContactSaga)]);

  yield all([takeLatest(companyManageActions.createCompanyRequest.type, createCompanySaga)]);
  yield all([takeLatest(companyManageActions.deleteCompanyRequest.type, deleteCompanySaga)]);
}

export default crmSaga;
