import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { areaCountriesActions } from '@redux/reducers/common/area-countries/areaCountriestReducer';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

const getAreaCountries = () => {
  return $http.get(`segments/counties`);
};

export function* getAreaCountriesSaga() {
  try {
    yield put(areaCountriesActions.setAreaCountriesLoading(true));
    const { data } = yield call(getAreaCountries);

    yield put(areaCountriesActions.getAreaCountriesSuccess(data.data));
  } catch (e) {
    yield put(areaCountriesActions.getAreaCountriesFailure(getDefaultError(e?.response?.data?.message)));
  }
}
