import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const lettingTransactionsState = (state: RootState) => state.insights.lettingsTransactions;

const getState = createSelector(lettingTransactionsState, (state) => state);

export const lettingInsightsSelectors = {
  getState,
};
