import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { marketingListActions } from '@redux/reducers/common/marketing-list/marketingListReducer';
import {
  GetMarketingListRequestPayload,
  GetMarketingListSuccessPayload,
} from '@redux/types/common/marketing-list/marketingListTypes';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

const getList = (params: GetMarketingListRequestPayload) => {
  return $http.get<GetMarketingListSuccessPayload>(`marketing-lists${getQueriesAsSearch(params)}`);
};

export function* getMarketingListSaga() {
  try {
    yield put(marketingListActions.setLoading(true));

    const params = { limit: 1000 };

    const { data } = yield call(() => getList(params));

    yield put(marketingListActions.getListSuccess(data));
  } catch (e) {
    yield put(marketingListActions.getListFailure(getDefaultError(e?.response?.data?.message)));
  }
}
