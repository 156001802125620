import { all, takeLatest } from 'redux-saga/effects';

import { savedViewsCampaignsSagas } from '@redux/reducers/saved_views/campaigns/campaignsReducer';
import { savedViewsCompaniesSagas } from '@redux/reducers/saved_views/companies/companiesReducer';
import { savedViewsContactsSagas } from '@redux/reducers/saved_views/contacts/contactsReducer';
import { savedViewsDisposalsSagas } from '@redux/reducers/saved_views/disposals/disposalsReducer';
import { savedViewDisposalsInsightsSagas } from '@redux/reducers/saved_views/insights/savedViewDisposalsInsightsReducer';
import { savedViewLettingInsightsSagas } from '@redux/reducers/saved_views/insights/savedViewLettingInsightsReducer';
import { savedViewsRequirementInsightsSagas } from '@redux/reducers/saved_views/insights/savedViewRequirementInsightsReducer';
import { savedViewSaleInsightsSagas } from '@redux/reducers/saved_views/insights/savedViewSaleInsightsReducer';
import { savedViewViewingsInsightsSagas } from '@redux/reducers/saved_views/insights/savedViewViewingsInsightsReducer';
import { savedViewsLettingsSagas } from '@redux/reducers/saved_views/lettings/lettingsReducer';
import { savedViewsSalesSagas } from '@redux/reducers/saved_views/sales/salesReducer';
import { savedViewSocietyAcquisitionInsightsSagas } from '@redux/reducers/saved_views/society-insights/savedViewSocietyAcquisitionInsightsReducer';
import { savedViewSocietyDisposalInsightsSagas } from '@redux/reducers/saved_views/society-insights/savedViewSocietyDisposalInsightsReducer';

function* savedViewSaga() {
  for (const [actionType, saga] of savedViewsLettingsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewsSalesSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewSocietyAcquisitionInsightsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewSocietyDisposalInsightsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewViewingsInsightsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewLettingInsightsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewSaleInsightsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewDisposalsInsightsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewsRequirementInsightsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewsDisposalsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewsContactsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewsCompaniesSagas) {
    yield all([takeLatest(actionType, saga)]);
  }

  for (const [actionType, saga] of savedViewsCampaignsSagas) {
    yield all([takeLatest(actionType, saga)]);
  }
}

export default savedViewSaga;
