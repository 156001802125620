import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const templatesState = (state: RootState) => state.user.templates;

const getState = createSelector(templatesState, (state) => state);

export const templatesSelectors = {
  getState,
};
