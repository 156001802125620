import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { companiesDnbActions } from '@redux/reducers/common/companies-dnb/companiesDnbReducer';
import { GetCompaniesDnbRequestPayload } from '@redux/types/common/companies-dnb/companiesDnbTypes';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getCompaniesDnbSaga(action: PayloadAction<GetCompaniesDnbRequestPayload>) {
  try {
    yield put(companiesDnbActions.setListLoading(true));

    const { data } = yield call(() =>
      $http.get(`/companies/dnb?SearchModeDescription:Basic&search=${action.payload.search}`)
    );

    yield put(companiesDnbActions.getListSuccess({ data: data.data, meta: data.meta }));
  } catch (e) {
    yield put(companiesDnbActions.getListFailure(getDefaultError(e?.response?.data?.message)));
  }
}
