import { all, takeLatest } from 'redux-saga/effects';

import { requirementManageActions } from '@redux/reducers/requirements/requirementManage/requirementManageReducer';

import { getRequirementManageSaga } from './requirementManage/requirementManageSaga';

function* requirementsSaga() {
  yield all([takeLatest(requirementManageActions.getRequirementRequest.type, getRequirementManageSaga)]);
}

export default requirementsSaga;
