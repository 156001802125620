import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { societyActions } from '@redux/reducers/common/society/societyReducer';
import {
  GetSocietyAcquisitionsListRequestPayload,
  GetSocietyAcquisitionsListSuccessPayload,
  GetSocietyDisposalsRequestPayload,
  GetSocietyDisposalsSuccessPayload,
  GetSocietyMembersRequestPayload,
  GetSocietyMembersSuccessPayload,
} from '@redux/types/common/society/societyTypes';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export const getSocietyMembersList = (params: GetSocietyMembersRequestPayload) => {
  return $http.get<GetSocietyMembersSuccessPayload>(`/society/members${getQueriesAsSearch(params)}`);
};

export const getSocietyAcquisitionsList = (params: GetSocietyAcquisitionsListRequestPayload) => {
  return $http.get<GetSocietyAcquisitionsListSuccessPayload>(`/society/acquisitions${getQueriesAsSearch(params)}`);
};

export const getSocietyDisposalsList = (params: GetSocietyDisposalsRequestPayload) => {
  return $http.get<GetSocietyDisposalsSuccessPayload>(`/society/disposals${getQueriesAsSearch(params)}`);
};

export function* getSocietyMembersSaga(action: PayloadAction<GetSocietyMembersRequestPayload>) {
  try {
    yield put(societyActions.setSocietyMembersLoading(true));
    const { data } = yield call(() => getSocietyMembersList(action.payload));

    yield put(societyActions.getSocietyMembersSuccess(data));
  } catch (e) {
    yield put(societyActions.getSocietyMembersFailure(getDefaultError(e?.message)));
  }
}

export function* getSocietyAcquisitionsSaga(action: PayloadAction<GetSocietyAcquisitionsListRequestPayload>) {
  try {
    yield put(societyActions.setSocietyAcquisitionsLoading(true));
    const { data } = yield call(() => getSocietyAcquisitionsList(action.payload));

    yield put(societyActions.getSocietyAcquisitionsSuccess(data));
  } catch (e) {
    yield put(societyActions.getSocietyAcquisitionsFailure(getDefaultError(e?.message)));
  }
}

export function* getSocietyDisposalsSaga(action: PayloadAction<GetSocietyDisposalsRequestPayload>) {
  try {
    yield put(societyActions.setSocietyDisposalsLoading(true));
    const { data } = yield call(() => getSocietyDisposalsList(action.payload));

    yield put(societyActions.getSocietyDisposalsSuccess(data));
  } catch (e) {
    yield put(societyActions.getSocietyDisposalsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
