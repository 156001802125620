import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse, CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { templatesActions } from '@redux/reducers/user/templates/templatesReducer';
import { templatesSelectors } from '@redux/selectors/settings/templates/templatesSelectors';
import {
  CreateEmailTemplateRequestPayload,
  EditEmailTemplateRequestPayload,
  GetTemplatesRequestPayload,
  GetTemplatesSuccessPayload,
  SendTestEmailPayload,
  TemplatesState,
} from '@redux/types/user/templates/templatesTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

const getList = (params: GetTemplatesRequestPayload, cancelToken: CancelToken) => {
  return $http.get<AxiosResponse<GetTemplatesSuccessPayload>>(
    `/admin/campaigns/email-templates${getQueriesAsSearch(params)}`,
    {
      cancelToken,
    }
  );
};

const getTemplateDetails = (id: Id) => $http.get(`/admin/campaigns/email-templates/${id}`);

const createTemplate = (data: CreateEmailTemplateRequestPayload['data']) =>
  $http.post(`/admin/campaigns/email-templates`, data);

const editTemplate = (payload: EditEmailTemplateRequestPayload) =>
  $http.put(`/admin/campaigns/email-templates/${payload.id}`, payload.data);

const sendTestEmail = (payload: SendTestEmailPayload) => {
  return $http.post(`/admin/campaigns/email-templates/${payload.id}/send-test-email`, payload.data);
};

export function* getTemplatesSaga(action: PayloadAction<GetTemplatesRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(templatesActions.setTemplatesLoading(true));

    action.payload.limit = 25;

    const { data } = yield call(() => getList(action.payload, source.token));

    yield put(templatesActions.getTemplatesSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);
    yield put(templatesActions.getTemplatesFailure(error));

    showNotification(error, 'error');
  } finally {
    const { loading }: TemplatesState = yield select(templatesSelectors.getState);

    if (loading) {
      source.cancel();
    }
  }
}

export function* getTemplateDetailsSaga(action: PayloadAction<Id>) {
  try {
    yield put(templatesActions.setTemplateDetailsLoading(true));

    const { data } = yield call(() => getTemplateDetails(action.payload));

    yield put(templatesActions.getTemplateDetailsSuccess(data.data));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);
    yield put(templatesActions.getTemplateDetailsFailure(error));

    showNotification(error, 'error');
  }
}

export function* createEmailTemplateSaga(action: PayloadAction<CreateEmailTemplateRequestPayload>) {
  try {
    yield put(templatesActions.setCreateEditTemplateLoading(true));

    const { data } = yield call(() => createTemplate(action.payload.data));

    yield put(templatesActions.createTemplateSuccess(data.data));

    if (action.payload?.callback) {
      action.payload.callback(data.data.id);
    }
  } catch (error) {
    error.errorMessages = {};
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((item: string) => {
        if (Array.isArray(error?.response?.data?.errors[item])) {
          error.errorMessages[item] = error?.response?.data?.errors[item][0];
        } else {
          error.errorMessages[item] = error?.response?.data?.errors[item];
        }
      });
    } else {
      error.errorMessages = [error?.message];
    }

    yield put(templatesActions.createTemplateFailure(error.errorMessages));
  }
}

export function* editEmailTemplateSaga(action: PayloadAction<EditEmailTemplateRequestPayload>) {
  try {
    yield put(templatesActions.setCreateEditTemplateLoading(true));

    const { data } = yield call(() => editTemplate(action.payload));

    yield put(templatesActions.editTemplateSuccess(data.data));

    if (action.payload?.callback) {
      action.payload.callback(data.data.id);
    }
  } catch (error) {
    error.errorMessages = {};
    if (error?.response?.data?.errors) {
      Object.keys(error?.response?.data?.errors).forEach((item: string) => {
        if (Array.isArray(error?.response?.data?.errors[item])) {
          error.errorMessages[item] = error?.response?.data?.errors[item][0];
        } else {
          error.errorMessages[item] = error?.response?.data?.errors[item];
        }
      });
    } else {
      error.errorMessages = [error?.message];
    }

    yield put(templatesActions.editTemplateFailure(error.errorMessages));
  }
}

export function* sendTestEmailSaga(action: PayloadAction<SendTestEmailPayload>) {
  try {
    yield put(templatesActions.setSendTestLoading(true));

    yield call(() => sendTestEmail(action.payload));

    yield put(templatesActions.sendTestSuccess(true));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);
    yield put(templatesActions.sendTestFailure(error));
    showNotification(error, 'error');
  }
}
