import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const requirementManageState = (state: RootState) => state.requirements.manage;

const getRequirementsManage = createSelector(requirementManageState, (state) => state);

export const requirementsSelectors = {
  getRequirementsManage,
};
