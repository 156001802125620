import { all, takeLatest } from 'redux-saga/effects';

import { disposalsInsightsActions } from '@redux/reducers/insights/disposals-insights/disposalsInsightsReducer';
import { lettingInsightsActions } from '@redux/reducers/insights/letting/lettingInsightsReducer';
import { insightsRequirementsActions } from '@redux/reducers/insights/requirements/requirementsInsightsReducer';
import { saleInsightsActions } from '@redux/reducers/insights/sale/saleInsightsReducer';
import { societyAcquisitionInsightsActions } from '@redux/reducers/insights/society-acquisition/societyAcquisitionInsightsReducer';
import { societyDisposalsActions } from '@redux/reducers/insights/society-disposal/societyDisposalInsightsReducer';
import { insightsViewingsActions } from '@redux/reducers/insights/viewings/insightsViewingsReducer';
import { viewingsInsightsActions } from '@redux/reducers/insights/viewings-insights/viewingsInsightsReducer';
import { getDisposalsInsightsSaga } from '@redux/sagas/insights/disposals-insights/disposalsInsightsSaga';
import { getLettingInsightsSaga } from '@redux/sagas/insights/letting-transactions/lettingTransactionsInsightsSaga';
import { getInsightsRequirementsSaga } from '@redux/sagas/insights/requirements/requirementsInsightsSaga';
import { getSaleInsightsSaga } from '@redux/sagas/insights/sale-transactions/saleTransactionsInsightsSaga';
import { getSocietyAcquisitionInsightsSaga as getSocietyAcquisitionInsightsSaga } from '@redux/sagas/insights/society-acquisition/societyAcquisitionInsightsSaga';
import { getSocietyDisposalInsightsSaga as getSocietyDisposalInsightsSaga } from '@redux/sagas/insights/society-disposal/societyDisposalInsightsSaga';
import { getViewingsInsightsSaga } from '@redux/sagas/insights/society-viewings/societyViewingsInsightsSaga';

import {
  changeInsightViewingsTaskStatusSaga,
  deleteInsightsViewingsTaskSaga,
  getInsightsViewingsSaga,
  getInsightsViewingsTableSaga,
  getInsightsViewingsTaskSaga,
  sendEmailConfirmationSaga,
} from './viewings/insightsViewingsSaga';

function* insightsSaga() {
  yield all([takeLatest(insightsViewingsActions.getListRequest.type, getInsightsViewingsSaga)]);
  yield all([takeLatest(insightsViewingsActions.getTableRequest.type, getInsightsViewingsTableSaga)]);
  yield all([takeLatest(insightsViewingsActions.getTaskRequest.type, getInsightsViewingsTaskSaga)]);
  yield all([takeLatest(insightsViewingsActions.deleteTaskRequest.type, deleteInsightsViewingsTaskSaga)]);
  yield all([takeLatest(insightsViewingsActions.changeTaskStatusRequest.type, changeInsightViewingsTaskStatusSaga)]);
  yield all([takeLatest(insightsViewingsActions.sendEmailRequest.type, sendEmailConfirmationSaga)]);

  yield all([takeLatest(societyAcquisitionInsightsActions.getListRequest.type, getSocietyAcquisitionInsightsSaga)]);
  yield all([takeLatest(societyDisposalsActions.getListRequest.type, getSocietyDisposalInsightsSaga)]);
  yield all([takeLatest(viewingsInsightsActions.getListRequest.type, getViewingsInsightsSaga)]);
  yield all([takeLatest(lettingInsightsActions.getListRequest.type, getLettingInsightsSaga)]);
  yield all([takeLatest(saleInsightsActions.getListRequest.type, getSaleInsightsSaga)]);

  yield all([takeLatest(insightsRequirementsActions.getStatisticRequest.type, getInsightsRequirementsSaga)]);

  yield all([takeLatest(disposalsInsightsActions.getListRequest.type, getDisposalsInsightsSaga)]);
}

export default insightsSaga;
