import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const mainState = (state: RootState) => state.insights.disposals;

const getState = createSelector(mainState, (state) => state);

export const societyDisposalInsightsSelectors = {
  getState,
};
