import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { areaRegionsActions } from '@redux/reducers/common/area-regions/areaRegionsReducer';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

const getAreaRegions = () => {
  return $http.get(`segments/regions`);
};

export function* getAreaRegionsSaga() {
  try {
    yield put(areaRegionsActions.setAreaRegionsLoading(true));
    const { data } = yield call(getAreaRegions);

    yield put(areaRegionsActions.getAreRegionsSuccess(data.data));
  } catch (e) {
    yield put(areaRegionsActions.getAreaRegionsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
