import { all, takeLatest } from 'redux-saga/effects';

import { addressLookupActions } from '@redux/reducers/common/address-lookup/addressLookupReducer';
import { areaCountriesActions } from '@redux/reducers/common/area-countries/areaCountriestReducer';
import { areaRegionsActions } from '@redux/reducers/common/area-regions/areaRegionsReducer';
import { areaTeamsActions } from '@redux/reducers/common/area-teams/areaTeamsReducer';
import { companiesActions } from '@redux/reducers/common/companies/companiesReducer';
import { companiesDnbActions } from '@redux/reducers/common/companies-dnb/companiesDnbReducer';
import { contactsActions } from '@redux/reducers/common/contacts/contactsReducer';
import { disposalsActions } from '@redux/reducers/common/disposals/disposalsReducer';
import { mapboxActions } from '@redux/reducers/common/mapbox-locations/mapboxLocationsReducer';
import { marketingListActions } from '@redux/reducers/common/marketing-list/marketingListReducer';
import { membersActions } from '@redux/reducers/common/members/membersReducer';
import { requirementsActions } from '@redux/reducers/common/requirements/requirementsReducer';
import { segmentsActions } from '@redux/reducers/common/segments/segmentsReducer';
import { societyActions } from '@redux/reducers/common/society/societyReducer';
import { societySegmentActions } from '@redux/reducers/common/society-segments/societySegmentsReducer';
import { usersActions } from '@redux/reducers/common/users/usersReducer';

import {
  getAddressDoctorFullAddressSaga,
  getAddressDoctorPreviewSaga,
  getAutocompleteAddressesPreview,
  getAutocompleteFinalAddress,
  getAutocompleteFullAddresses,
  getCoordsFromAddressSaga,
} from './address-lookup/addressLookupSaga';
import { getAreaCountriesSaga } from './area-countries/areaCountriesSaga';
import { getAreaRegionsSaga } from './area-regions/areaRegionsSaga';
import { getAreaTeamsSaga } from './area-teams/areaTeamsSaga';
import { getCompaniesSaga } from './companies/companiesSaga';
import { getCompaniesDnbSaga } from './companies-dnb/companiesDnbSaga';
import { getContactsSaga } from './contacts/contactsSaga';
import { getDisposalsSaga } from './disposals/disposalsSaga';
import { getMapboxLocationsSaga } from './mapbox-locations/mapboxLocationsSaga';
import { getMarketingListSaga } from './marketing-list/marketingListSaga';
import { getMembersSaga } from './members/membersSaga';
import { getRequirementsSaga } from './requirements/requirementsSaga';
import { getSegmentsSaga } from './segments/segmentsSaga';
import { getSocietyAcquisitionsSaga, getSocietyDisposalsSaga, getSocietyMembersSaga } from './society/societySaga';
import { getSocietySegmentsSaga } from './society-segments/societySegmentsSaga';
import { getUsersSaga } from './users/usersSaga';

function* commonSaga() {
  yield all([takeLatest(societySegmentActions.getSocietySegmentsRequest.type, getSocietySegmentsSaga)]);
  yield all([takeLatest(areaTeamsActions.getAreaTeamsRequest.type, getAreaTeamsSaga)]);
  yield all([takeLatest(areaRegionsActions.getAreaRegionsRequest.type, getAreaRegionsSaga)]);
  yield all([takeLatest(areaCountriesActions.getAreaCountriesRequest.type, getAreaCountriesSaga)]);
  yield all([takeLatest(usersActions.getUsersRequest.type, getUsersSaga)]);
  yield all([takeLatest(disposalsActions.getDisposalsRequest.type, getDisposalsSaga)]);
  yield all([takeLatest(contactsActions.getContactsRequest.type, getContactsSaga)]);
  yield all([takeLatest(companiesActions.getCompaniesRequest.type, getCompaniesSaga)]);
  yield all([takeLatest(requirementsActions.getRequirementsRequest.type, getRequirementsSaga)]);
  yield all([takeLatest(societyActions.getSocietyMembersRequest.type, getSocietyMembersSaga)]);
  yield all([takeLatest(societyActions.getSocietyAcquisitionsRequest.type, getSocietyAcquisitionsSaga)]);
  yield all([takeLatest(societyActions.getSocietyDisposalsRequest.type, getSocietyDisposalsSaga)]);
  yield all([takeLatest(mapboxActions.getMapboxLocationRequest.type, getMapboxLocationsSaga)]);
  yield all([takeLatest(marketingListActions.getListRequest.type, getMarketingListSaga)]);
  yield all([takeLatest(segmentsActions.getSegmentsRequest.type, getSegmentsSaga)]);
  yield all([takeLatest(membersActions.getListRequest.type, getMembersSaga)]);

  // Shared Index page sagas
  yield all([takeLatest(addressLookupActions.getPreviewAddressAutocomplete.type, getAutocompleteAddressesPreview)]);
  yield all([takeLatest(addressLookupActions.getAutocompleteFullAddress.type, getAutocompleteFullAddresses)]);
  yield all([takeLatest(addressLookupActions.getAutocompleteFinalAddress.type, getAutocompleteFinalAddress)]);
  yield all([takeLatest(addressLookupActions.getAddressDoctorPreviewRequest.type, getAddressDoctorPreviewSaga)]);
  yield all([
    takeLatest(addressLookupActions.getAddressDoctorFullAddressRequest.type, getAddressDoctorFullAddressSaga),
  ]);
  yield all([takeLatest(addressLookupActions.getCoordsFromAddressRequest.type, getCoordsFromAddressSaga)]);

  yield all([takeLatest(companiesDnbActions.getListRequest.type, getCompaniesDnbSaga)]);
}

export default commonSaga;
