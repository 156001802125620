import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { twitterConnectionActions } from '@redux/reducers/user/twitter-connection/twitterConnectionReducer';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* connectTwitterSaga() {
  try {
    yield put(twitterConnectionActions.setTwitterLoading(true));

    const { data } = yield call(() => $http.post('/twitter/authorise'));

    window.location.href = data.url;
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);

    yield put(twitterConnectionActions.connectTwitterFailure(error));
    showNotification(error, 'error');
  }
}

export function* disconnectTwitterSaga() {
  try {
    yield put(twitterConnectionActions.setTwitterLoading(true));

    const { data } = yield call(() => $http.post('/twitter/disconnect'));

    yield put(configActions.setUser(data));

    yield put(twitterConnectionActions.disconnectTwitterSuccess());
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);

    yield put(twitterConnectionActions.disconnectTwitterFailure(error));
    showNotification(error, 'error');
  }
}
