import { all, fork } from 'redux-saga/effects';

import campaignsSaga from './campaigns/campaignsSaga';
import commonSaga from './common/commonSaga';
import crmSaga from './crm/crmSaga';
import diarySaga from './diary/diarySaga';
import disposalsSaga from './disposals/disposalsSaga';
import insightsSaga from './insights/insightsSaga';
import lookupSaga from './lookup/lookupSaga';
import requirementsSaga from './requirements/requirementsSaga';
import savedViewSaga from './saved_view/savedViewSaga';
import userSaga from './user/userSaga';

export function* rootSaga() {
  yield all([fork(campaignsSaga)]);
  yield all([fork(commonSaga)]);
  yield all([fork(crmSaga)]);
  yield all([fork(diarySaga)]);
  yield all([fork(disposalsSaga)]);
  yield all([fork(insightsSaga)]);
  yield all([fork(lookupSaga)]);
  yield all([fork(requirementsSaga)]);
  yield all([fork(savedViewSaga)]);
  yield all([fork(userSaga)]);
}
