import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { disposalsActions } from '@redux/reducers/common/disposals/disposalsReducer';
import { GetDisposalsRequestPayload, GetDisposalsSuccessPayload } from '@redux/types/common/disposals/disposalTypes';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $https = getHTTPClient();

export const getDisposalsList = (params: GetDisposalsRequestPayload) => {
  return $https.get<GetDisposalsSuccessPayload>(`/disposals${getQueriesAsSearch(params)}`);
};

export function* getDisposalsSaga(action: PayloadAction<GetDisposalsRequestPayload>) {
  try {
    yield put(disposalsActions.setDisposalsLoading(true));
    const { data } = yield call(() => getDisposalsList(action.payload));

    yield put(disposalsActions.getDisposalsSuccess(data));
  } catch (e) {
    yield put(disposalsActions.getDisposalsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
