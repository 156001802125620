import { all, takeLatest } from 'redux-saga/effects';

import { disposalManageInsightsActions } from '@redux/reducers/disposals/disposalInsights/disposalInsightsReducer';
import { disposalMarketplacePerformanceActions } from '@redux/reducers/disposals/disposalMarketplacePerformance/marketplacePerformanceReducer';

import { getDisposalManageInsightsData } from './disposalManageInsights/disposalManageInsightsSaga';
import { getDisposalManageMarketplacePerformanceSaga } from './disposalManageMarketplacePerformance/disposalManageMarketplacePerformanceSaga';

function* disposalsSaga() {
  yield all([takeLatest(disposalManageInsightsActions.getDataRequest.type, getDisposalManageInsightsData)]);

  yield all([
    takeLatest(disposalMarketplacePerformanceActions.getDataRequest.type, getDisposalManageMarketplacePerformanceSaga),
  ]);
}

export default disposalsSaga;
