import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { campaignRecipientsActions } from '@redux/reducers/campaigns/recipients/campaignRecipientsReducer';
import { campaignsSelectors } from '@redux/selectors/campaigns/campaignsSelectors';
import { CampaignRecipientsState } from '@redux/types/campaigns/campaignRecipientsTypes';
import { GetCampaignRecipientsRequestPayload } from '@redux/types/campaigns/manageCampaignTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getRecipientsDrawerSaga(action: PayloadAction<GetCampaignRecipientsRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(campaignRecipientsActions.setRecipientsLoading(true));

    action.payload.limit = 20;

    const { data } = yield call(() =>
      $http.post(`/emails-campaigns/contacts`, action.payload, { cancelToken: source.token })
    );

    yield put(campaignRecipientsActions.getRecipientsSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message || e?.message);
    yield put(campaignRecipientsActions.getRecipientsFailure(error));

    showNotification(error, 'error');
  } finally {
    const { loading }: CampaignRecipientsState = yield select(campaignsSelectors.getCampaignRecipientsState);

    if (loading) {
      source.cancel();
    }
  }
}

export function* getUpdatedRecipientsSaga(action: PayloadAction<GetCampaignRecipientsRequestPayload>) {
  try {
    yield put(campaignRecipientsActions.setUpdatedRecipientsLoading(true));

    action.payload.limit = 20;

    const { data } = yield call(() => $http.post(`/emails-campaigns/contacts`, action.payload));

    yield put(campaignRecipientsActions.getUpdatedRecipientsSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message || e?.message);
    yield put(campaignRecipientsActions.getUpdatedRecipientsFailure(error));

    showNotification(error, 'error');
  }
}
