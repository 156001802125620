import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { configActions } from '@redux/reducers/config/configReducer';
import { profileActions } from '@redux/reducers/user/profile/profileReducer';
import { UpdateProfileRequestPayload } from '@redux/types/user/profile/profileTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

const updateProfile = ({ data, id }: UpdateProfileRequestPayload) => {
  return $http.put(`/users/${id}/update`, data);
};

export function* updateProfileSaga(action: PayloadAction<UpdateProfileRequestPayload>) {
  try {
    yield put(profileActions.setUpdateProfileLoading(true));

    const { data } = yield call(() => updateProfile(action.payload));

    yield put(configActions.updateUserInfo(data));
    yield put(profileActions.updateProfileSuccess());
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message || e?.message);
    yield put(profileActions.updateProfileError(error));

    showNotification(error, 'error');
  }
}
