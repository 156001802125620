import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { requirementManageActions } from '@redux/reducers/requirements/requirementManage/requirementManageReducer';
import { requirementsSelectors } from '@redux/selectors/requirements/requirements';
import { RequirementManageState } from '@redux/types/requirements/requirementsTypes';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

const getRequirementManage = (id: Id, cancelToken: CancelToken) => {
  return $http.get(`/acquisitions/${id}`, { cancelToken });
};

export function* getRequirementManageSaga(action: PayloadAction<Id>) {
  const source = getCancelRequestSource();

  try {
    yield put(requirementManageActions.setRequirementLoading(true));

    const { data } = yield call(() => getRequirementManage(action.payload, source.token));

    yield put(requirementManageActions.getRequirementSuccess(data));
  } catch (e) {
    yield put(requirementManageActions.getRequirementFailure(getDefaultError(e?.response?.data?.message)));
  } finally {
    const { loading }: RequirementManageState = yield select(requirementsSelectors.getRequirementsManage);

    if (loading) {
      source.cancel();
    }
  }
}
