import { all, takeLatest } from 'redux-saga/effects';

import { manageTasksActions } from '@redux/reducers/diary/tasks/tasksReducer';
import { createNewTaskSaga } from '@redux/sagas/diary/tasksSaga';

function* diarySaga() {
  yield all([takeLatest(manageTasksActions.createTasksRequest.type, createNewTaskSaga)]);
}

export default diarySaga;
