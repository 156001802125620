import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient, yieldFailureResponse, YieldResponse, yieldSuccessResponse } from '@core/http-client';
import { updatePasswordActions } from '@redux/reducers/user/password/updatePasswordReducer';
import { UpdatePasswordRequest, UpdatePasswordRequestBody } from '@redux/types/user/password/updatePasswordTypes';

const apiUrl = '/users';

const $http = getHTTPClient();

export function* updatePasswordSaga(action: PayloadAction<UpdatePasswordRequest['payload']>) {
  const {
    payload: { userId, body },
    resolve,
    reject,
  } = action.payload;

  yield put(updatePasswordActions.handleRequest());

  const { error, response }: YieldResponse = yield call(() =>
    $http
      .put<UpdatePasswordRequestBody, AxiosResponse>(`${apiUrl}/${userId}/update/password`, body)
      .then(yieldSuccessResponse)
      .catch(yieldFailureResponse)
  );

  if (response) {
    yield put(updatePasswordActions.handleSuccess());
    yield call(() => resolve());
  } else if (error) {
    // Build error messages list
    if (error?.isAxiosError && error?.response?.data?.errors) {
      error.errorMessages = [];
      Object.values(error?.response?.data?.errors).forEach((item) => {
        if (Array.isArray(item) && item.length) {
          error.errorMessages = [...error.errorMessages, ...item];
        } else if (typeof item === 'string' && item) {
          error.errorMessages.push(item);
        }
      });
    } else {
      error.errorMessages = [error?.message];
    }

    yield put(updatePasswordActions.handleFailure(error.errorMessages));
    yield call(() => reject(error));
  }
}
