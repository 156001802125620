import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const viewingsState = (state: RootState) => state.insights.viewings;

const getState = createSelector(viewingsState, (state) => state);

export const insightsViewingsSelectors = {
  getState,
};
