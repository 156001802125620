import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { disposalMarketplacePerformanceActions } from '@redux/reducers/disposals/disposalMarketplacePerformance/marketplacePerformanceReducer';
import { GetDisposalMarketplacePerformanceSuccess } from '@redux/types/disposal/disposalMarketplacePerformance';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getDisposalManageMarketplacePerformanceSaga(action: PayloadAction<Id>) {
  yield put(disposalMarketplacePerformanceActions.setLoading(true));

  try {
    const { data } = yield call(() =>
      $http.get<GetDisposalMarketplacePerformanceSuccess>(`/disposals/${action.payload}/marketplace-insights`)
    );

    yield put(disposalMarketplacePerformanceActions.getDataSuccess(data));
  } catch (error) {
    const errorMessage = getDefaultError(error.response?.data?.message || error.message);
    showNotification(errorMessage, 'error');
    yield put(disposalMarketplacePerformanceActions.getDataFailure(getDefaultError(error?.response?.data?.message)));
  }
}
