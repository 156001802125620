import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const savedViewsSalesState = (state: RootState) => state.savedViews.sales;

const getState = createSelector(savedViewsSalesState, (state) => state);
const unpinnedSavedView = createSelector(savedViewsSalesState, (state) => state.unpinnedSavedView);

export const savedViewsSalesSelectors = {
  getState,
  unpinnedSavedView,
};
