import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient, yieldFailureResponse, yieldSuccessResponse } from '@core/http-client';
import { societyDisposalsActions } from '@redux/reducers/insights/society-disposal/societyDisposalInsightsReducer';
import { societyDisposalInsightsSelectors } from '@redux/selectors/insights/societyDisposalInsights';
import { GetSocietyInsightsFilteringRequestPayload } from '@redux/types/insights/insightsTypes';
import {
  GetSocietyDisposalInsightsResponse,
  SocietyDisposalInsightsState,
} from '@redux/types/insights/society-disposal/societyDisposalInsightsTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getSocietyDisposalInsightsSaga(action: PayloadAction<GetSocietyInsightsFilteringRequestPayload>) {
  const source = getCancelRequestSource();

  yield put(societyDisposalsActions.setListLoading(true));

  const { error, response }: GetSocietyDisposalInsightsResponse = yield call(() =>
    $http
      .get(`/society/disposals/statistics${getQueriesAsSearch(action.payload)}`, {
        cancelToken: source.token,
      })
      .then(yieldSuccessResponse)
      .catch(yieldFailureResponse)
  );

  if (response) {
    yield put(societyDisposalsActions.getListSuccess(response.data));
  } else if (error) {
    const errorMessage = getDefaultError(error.response?.data?.message || error.message);

    // Set the societies invalid flag
    if (error.response?.status === 403) {
      yield put(societyDisposalsActions.setListSocietiesInvalid(true));
    }

    // Show error notification
    showNotification(errorMessage, 'error');

    // Set the message / errors list
    yield put(societyDisposalsActions.getListFailure(errorMessage));
  }

  const { loading }: SocietyDisposalInsightsState = yield select(societyDisposalInsightsSelectors.getState);
  if (loading) {
    source.cancel();
  }
}
