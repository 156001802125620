import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse, CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { campaignsActions } from '@redux/reducers/campaigns/campaigns-list/campaignsListReducer';
import { campaignsSelectors } from '@redux/selectors/campaigns/campaignsSelectors';
import {
  CampaignsState,
  GetCampaignsRequestPayload,
  GetCampaignsSuccessPayload,
} from '@redux/types/campaigns/campaignsTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

const getList = (params: GetCampaignsRequestPayload, cancelToken: CancelToken) => {
  return $http.get<AxiosResponse<GetCampaignsSuccessPayload>>(`/emails-campaigns${getQueriesAsSearch(params)}`, {
    cancelToken,
  });
};

export function* getCampaignsListSaga(action: PayloadAction<GetCampaignsRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(campaignsActions.setCampaignsLoading(true));

    const { data } = yield call(() => getList(action.payload, source.token));

    yield put(campaignsActions.getCampaignsSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);
    yield put(campaignsActions.getCampaignsFailure(error));

    showNotification(error, 'error');
  } finally {
    const { loading }: CampaignsState = yield select(campaignsSelectors.getCampaignsListState);

    if (loading) {
      source.cancel();
    }
  }
}
