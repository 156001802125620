import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const savedViewsCampaignsState = (state: RootState) => state.savedViews.campaigns;

const getState = createSelector(savedViewsCampaignsState, (state) => state);

export const savedViewsCampaignsSelectors = {
  getState,
};
