import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient, yieldFailureResponse, yieldSuccessResponse } from '@core/http-client';
import { saleInsightsActions } from '@redux/reducers/insights/sale/saleInsightsReducer';
import { saleInsightsSelectors } from '@redux/selectors/insights/saleInsights';
import {
  GetSaleInsightsRequestPayload,
  GetSaleInsightsResponse,
  SaleInsightsState,
} from '@redux/types/insights/sale/saleInsightsTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getSaleInsightsSaga(action: PayloadAction<GetSaleInsightsRequestPayload>) {
  const source = getCancelRequestSource();

  yield put(saleInsightsActions.setListLoading(true));

  const { error, response }: GetSaleInsightsResponse = yield call(() =>
    $http
      .get(`/transactions/sales/statistics${getQueriesAsSearch(action.payload)}`, {
        cancelToken: source.token,
      })
      .then(yieldSuccessResponse)
      .catch(yieldFailureResponse)
  );

  if (response) {
    yield put(saleInsightsActions.getListSuccess(response.data));
  } else if (error) {
    const errorMessage = getDefaultError(error.response?.data?.message || error.message);

    // Set the societies invalid flag
    if (error.response?.status === 403) {
      yield put(saleInsightsActions.setListSocietiesInvalid(true));
    }

    // Show error notification
    showNotification(errorMessage, 'error');

    // Set the message / errors list
    yield put(saleInsightsActions.getListFailure(errorMessage));
  }

  const { loading }: SaleInsightsState = yield select(saleInsightsSelectors.getState);

  if (loading) {
    source.cancel();
  }
}
