import { PayloadAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { call, put, select } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { insightsViewingsActions } from '@redux/reducers/insights/viewings/insightsViewingsReducer';
import { insightsViewingsSelectors } from '@redux/selectors/insights/insightsViewings';
import {
  ChangeInsightsTaskStatusRequestPayload,
  GetInsightsRequestPayload,
  GetInsightsSuccessPayload,
  GetInsightsTableSuccessPayload,
  GetInsightsTaskRequestPayload,
  InsightsViewingsState,
  SendEmailConfirmationRequestPayload,
} from '@redux/types/insights/viewings/insightsViewingsTypes';
import { showNotification } from '@shared/components/notification';
import {
  InsightsViewingTask,
  InsightsViewingTaskDTO,
  ViewingsEmailConfirmationDTO,
} from '@shared/models/insightsViewing/insightsViewingTask';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const apiUrl = '/stats';
const taskApiUrl = '/tasks';

const $http = getHTTPClient();

const getInsightsViewing = (params: GetInsightsRequestPayload, cancelToken: CancelToken) => {
  return $http.get<GetInsightsSuccessPayload>(`${apiUrl}/viewings${getQueriesAsSearch(params)}`, {
    cancelToken,
  });
};

const getInsightsViewingsTable = (params: GetInsightsRequestPayload) => {
  return $http.get<GetInsightsTableSuccessPayload>(`${apiUrl}/viewings-table${getQueriesAsSearch(params)}`);
};

const getInsightsTask = (id: Id) => {
  return $http.get(`${taskApiUrl}/${id}`);
};

const deleteTask = (id: Id) => {
  return $http.delete(`${taskApiUrl}/${id}`);
};

const changeTaskStatus = (data: ChangeInsightsTaskStatusRequestPayload) => {
  return $http.patch<{ status: number }, InsightsViewingTask>(`${taskApiUrl}/${data.id}`, { status: data.status });
};

export const sendInsightViewingEmailConfirmation = (id: Id, data: ViewingsEmailConfirmationDTO) => {
  return $http.post(`/diary/${id}/email`, data);
};

export const updatesInsightsTask = (id: Id, data: InsightsViewingTaskDTO) => {
  return $http.patch(`${taskApiUrl}/${id}`, data);
};
export function* getInsightsViewingsSaga(action: PayloadAction<GetInsightsRequestPayload>) {
  const source = getCancelRequestSource();

  try {
    yield put(insightsViewingsActions.setListLoading(true));

    const { data } = yield call(() => getInsightsViewing(action.payload, source.token));

    yield put(insightsViewingsActions.getListSuccess(data));
  } catch (e) {
    yield put(insightsViewingsActions.getListFailure(getDefaultError(e?.message)));
  } finally {
    const { loading }: InsightsViewingsState = yield select(insightsViewingsSelectors.getState);

    if (loading) {
      source.cancel();
    }
  }
}

export function* getInsightsViewingsTableSaga(action: PayloadAction<GetInsightsRequestPayload>) {
  try {
    yield put(insightsViewingsActions.setTableLoading(true));

    const { data } = yield call(() => getInsightsViewingsTable(action.payload));

    yield put(insightsViewingsActions.getTableSuccess(data));
  } catch (e) {
    yield put(insightsViewingsActions.getTableFailure(getDefaultError(e?.message)));
  }
}

export function* getInsightsViewingsTaskSaga(action: PayloadAction<GetInsightsTaskRequestPayload>) {
  try {
    yield put(insightsViewingsActions.setTaskLoading(true));

    const { data } = yield call(() => getInsightsTask(action.payload.id));

    yield put(insightsViewingsActions.getTaskSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
    yield put(insightsViewingsActions.getTaskFailure(getDefaultError(e?.message)));

    if (action.payload?.method) {
      action.payload?.method();
    }
  }
}

export function* deleteInsightsViewingsTaskSaga(action: PayloadAction<Id>) {
  try {
    yield put(insightsViewingsActions.setDeleteTaskLoading(true));

    yield call(() => deleteTask(action.payload));

    yield put(insightsViewingsActions.deleteTaskSuccess(action.payload));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
    yield put(insightsViewingsActions.deleteTaskFailure(getDefaultError(e?.message)));
  }
}

export function* changeInsightViewingsTaskStatusSaga(action: PayloadAction<ChangeInsightsTaskStatusRequestPayload>) {
  try {
    yield put(insightsViewingsActions.changeTaskStatusLoading(true));

    const { data } = yield call(() => changeTaskStatus(action.payload));

    yield put(insightsViewingsActions.changeTaskStatusSuccess(data));
  } catch (e) {
    showNotification(getDefaultError(e?.message), 'error');
    yield put(insightsViewingsActions.changeTaskStatusFailure(getDefaultError(e?.message)));
  }
}

export function* sendEmailConfirmationSaga(action: PayloadAction<SendEmailConfirmationRequestPayload>) {
  try {
    yield put(insightsViewingsActions.setSendEmailLoading(true));

    yield call(() => sendInsightViewingEmailConfirmation(action.payload.id, action.payload.data));

    yield put(insightsViewingsActions.sendEmailSuccess());
  } catch (e) {
    yield put(
      insightsViewingsActions.sendEmailFailure(
        getDefaultError(e?.message, 'There was an error sending this email. Please contact your system administrator')
      )
    );
  }
}
