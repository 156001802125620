import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { lookupActions } from '@redux/reducers/lookup/lookupReducer';
import { AbortPayload } from '@shared/types/redux';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

// --- Disposals ---

export interface GetLookupDisposalsFilters {
  search: string;
}

export type GetLookupDisposalsPayload = AbortPayload<GetLookupDisposalsFilters>;

function* getLookupDisposalsSaga(action: PayloadAction<GetLookupDisposalsPayload>) {
  const { payload, controller } = action.payload;

  try {
    yield put(lookupActions.getDisposalsPreRequest());

    const { data } = yield call(() =>
      $http.get(`/disposals${getQueriesAsSearch({ ...payload, limit: '10' })}`, {
        signal: controller.signal,
      })
    );

    yield put(lookupActions.getDisposalsSuccess(data));
  } catch (e: any) {
    yield put(lookupActions.getDisposalsFailure(getDefaultError(e?.response?.message)));
  }
}

// --- Users ---

export interface GetLookupUsersFilters {
  search: string;
}

export type GetLookupUsersPayload = AbortPayload<GetLookupUsersFilters>;

function* getLookupUsersSaga(action: PayloadAction<GetLookupUsersPayload>) {
  const { payload, controller } = action.payload;

  try {
    yield put(lookupActions.getUsersPreRequest());

    const { data } = yield call(() =>
      $http.get(`/users${getQueriesAsSearch({ ...payload, limit: '10' })}`, {
        signal: controller.signal,
      })
    );

    yield put(lookupActions.getUsersSuccess(data));
  } catch (e: any) {
    yield put(lookupActions.getUsersFailure(getDefaultError(e?.response?.message)));
  }
}

export default function* configSaga() {
  yield all([takeLatest(lookupActions.getDisposalsRequest.type, getLookupDisposalsSaga)]);
  yield all([takeLatest(lookupActions.getUsersRequest.type, getLookupUsersSaga)]);
}
