import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { membersActions } from '@redux/reducers/common/members/membersReducer';
import { GetMembersRequestPayload, GetMembersSuccessPayload } from '@redux/types/common/members/membersTypes';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export const getUsersList = (params: GetMembersRequestPayload) => {
  return $http.get<GetMembersSuccessPayload>(`/society/members${getQueriesAsSearch(params)}`);
};

export function* getMembersSaga(action: PayloadAction<GetMembersRequestPayload>) {
  try {
    yield put(membersActions.setListLoading(true));
    const { data } = yield call(() => getUsersList(action.payload));

    yield put(membersActions.getListSuccess(data));
  } catch (e) {
    yield put(membersActions.getListFailure(getDefaultError(e?.response?.data?.message)));
  }
}
