import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient, yieldFailureResponse, YieldResponse, yieldSuccessResponse } from '@core/http-client';
import { companyManageActions } from '@redux/reducers/crm/companies/manage/companiesManageReducer';
import { CreateCompanyDTO, DeleteCrmCompanyPayload } from '@redux/types/crm/companies/companiesTypes';
import { showNotification } from '@shared/components/notification';
import { PromisePayload } from '@shared/types/redux';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export function* createCompanySaga(action: PayloadAction<PromisePayload<{ data: CreateCompanyDTO }>>) {
  const { payload, resolve, reject } = action.payload;
  try {
    yield put(companyManageActions.setCreateLoading(true));
    const { data } = yield call(() => $http.post('/companies', payload.data));
    yield put(companyManageActions.createCompanySuccess(data));
    yield call(() => resolve(data));
  } catch (e) {
    yield put(companyManageActions.createCompanyFailure(getDefaultError(e?.response?.data?.message)));
    yield call(() => reject(e));
  }
}

export function* deleteCompanySaga(action: PayloadAction<DeleteCrmCompanyPayload['payload']>) {
  const { payload, resolve, reject } = action.payload;

  const { id, ...restPayload } = payload;

  yield put(companyManageActions.setDeleteCompanyLoading(true));

  const { response, error }: YieldResponse = yield call(() =>
    $http
      .delete(`companies/${id}${getQueriesAsSearch(restPayload)}`)
      .then(yieldSuccessResponse)
      .catch(yieldFailureResponse)
  );

  if (response) {
    yield put(companyManageActions.deleteCompanySuccess());
    yield call(() => resolve());
  } else if (error) {
    yield put(companyManageActions.deleteCompanyFailure());
    yield call(() => reject());

    showNotification(getDefaultError(error?.response?.data?.message), 'error');
  }
}
