import { all, call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { segmentsActions } from '@redux/reducers/common/segments/segmentsReducer';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getSegmentsSaga() {
  try {
    yield put(segmentsActions.setLoading(true));

    const { regions, countries, team, society } = yield all({
      regions: call(() => $http.get(`segments/regions`)),
      countries: call(() => $http.get(`segments/counties`)),
      team: call(() => $http.get(`segments/team`)),
      society: call(() => $http.get(`segments/society`)),
    });

    yield put(
      segmentsActions.getSegmentsSuccess({
        regions: regions.data.data,
        countries: countries.data.data,
        team: team.data.data,
        society: society.data.data,
      })
    );
  } catch (e) {
    yield put(segmentsActions.getSegmentsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
