import { all, takeLatest } from 'redux-saga/effects';

import { updatePasswordActions } from '@redux/reducers/user/password/updatePasswordReducer';
import { profileActions } from '@redux/reducers/user/profile/profileReducer';
import { templatesActions } from '@redux/reducers/user/templates/templatesReducer';
import { twitterConnectionActions } from '@redux/reducers/user/twitter-connection/twitterConnectionReducer';

import { updatePasswordSaga } from './password/updatePasswordSaga';
import { updateProfileSaga } from './profile/profileSaga';
import {
  createEmailTemplateSaga,
  editEmailTemplateSaga,
  getTemplateDetailsSaga,
  getTemplatesSaga,
  sendTestEmailSaga,
} from './templates/templatesSaga';
import { connectTwitterSaga, disconnectTwitterSaga } from './twitter-connection/twitterConnectionSaga';

function* userSaga() {
  yield all([takeLatest(updatePasswordActions.submitRequest.type, updatePasswordSaga)]);

  yield all([takeLatest(twitterConnectionActions.connectTwitterRequest.type, connectTwitterSaga)]);
  yield all([takeLatest(twitterConnectionActions.disconnectTwitterRequest.type, disconnectTwitterSaga)]);

  yield all([takeLatest(profileActions.updateProfileRequest.type, updateProfileSaga)]);

  yield all([takeLatest(templatesActions.getTemplatesRequest.type, getTemplatesSaga)]);
  yield all([takeLatest(templatesActions.getTemplateDetailsRequest.type, getTemplateDetailsSaga)]);
  yield all([takeLatest(templatesActions.createTemplateRequest.type, createEmailTemplateSaga)]);
  yield all([takeLatest(templatesActions.editTemplateRequest.type, editEmailTemplateSaga)]);
  yield all([takeLatest(templatesActions.sendTestRequest.type, sendTestEmailSaga)]);
}

export default userSaga;
