import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { contactsActions } from '@redux/reducers/common/contacts/contactsReducer';
import { GetCompaniesSuccessPayload } from '@redux/types/common/companies/companiesTypes';
import { GetContactsRequestPayload } from '@redux/types/common/contacts/contactsTypes';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export const getContactsList = (params: GetContactsRequestPayload) => {
  return $http.get<GetCompaniesSuccessPayload>(`/contacts${getQueriesAsSearch(params)}`);
};

export function* getContactsSaga(action: PayloadAction<GetContactsRequestPayload>) {
  try {
    yield put(contactsActions.setContactsLoading(true));
    const { data } = yield call(() => getContactsList(action.payload));

    yield put(contactsActions.getContactsSuccess(data));
  } catch (e) {
    yield put(contactsActions.getContactsFailure(getDefaultError(e?.response?.data?.message)));
  }
}
