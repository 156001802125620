import { PayloadAction } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { call, put } from 'redux-saga/effects';

import { getHTTPClient } from '@core/http-client';
import { campaignResultsActions } from '@redux/reducers/campaigns/results/campaignResultsReducer';
import { GetCampaignReportTableParams } from '@redux/types/campaigns/campaignResultsTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError, getQueriesAsSearch } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getResultsSaga(action: PayloadAction<Id>) {
  try {
    yield put(campaignResultsActions.setResultsLoading(true));

    const { data } = yield call(() => $http.get(`/emails-campaigns/${action.payload}/general-statistics`));

    yield put(campaignResultsActions.getResultsSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.message);
    yield put(campaignResultsActions.getResultsFailure(error));

    showNotification(error, 'error');
  }
}

export function* getRecipientsResultsSaga(action: PayloadAction<GetCampaignReportTableParams>) {
  try {
    yield put(campaignResultsActions.setRecipientsResultsLoading(true));

    const { data } = yield call(() =>
      $http.get(
        `/emails-campaigns/${action.payload.campaignId}/recipients-statistics${getQueriesAsSearch(
          omit(action.payload, ['campaignId'])
        )}`
      )
    );

    yield put(campaignResultsActions.getRecipientsResultsSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.message);
    yield put(campaignResultsActions.getRecipientsResultsFailure(error));

    showNotification(error, 'error');
  }
}

export function* getRealtimeResultsSaga(action: PayloadAction<GetCampaignReportTableParams>) {
  try {
    yield put(campaignResultsActions.setRealtimeResultsLoading(true));

    const { data } = yield call(() =>
      $http.get(
        `/emails-campaigns/${action.payload.campaignId}/realtime-statistics${getQueriesAsSearch(
          omit(action.payload, ['campaignId'])
        )}`
      )
    );

    yield put(campaignResultsActions.getRealtimeResultsSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.message);
    yield put(campaignResultsActions.getRealtimeResultsFailure(error));

    showNotification(error, 'error');
  }
}
