import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const disposalsInsightsState = (state: RootState) => state.insights.disposalsInsights;

const getState = createSelector(disposalsInsightsState, (state) => state);

export const disposalsInsightsSelectors = {
  getState,
};
