import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@redux/reducers/rootReducer';

const savedViewsState = (state: RootState) => state.savedViews.viewingsInsights;

const getState = createSelector(savedViewsState, (state) => state);
const unpinnedSavedView = createSelector(savedViewsState, (state) => state.unpinnedSavedView);

export const savedViewViewingsInsightsSelectors = {
  getState,
  unpinnedSavedView,
};
