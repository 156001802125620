import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { getCancelRequestSource, getHTTPClient } from '@core/http-client';
import { manageSinglePropertyCampaignActions } from '@redux/reducers/campaigns/manage-single-property-campaign/manageSinglePropertyCampaignReducer';
import { campaignRecipientsActions } from '@redux/reducers/campaigns/recipients/campaignRecipientsReducer';
import {
  CreateSinglePropertyCampaignRequestPayload,
  GetManageSinglePropertyTemplatePayload,
  ScheduleSinglePropertyEmailCampaignPayload,
  SendTestSinglePropertyEmailCampaignPayload,
} from '@redux/types/campaigns/manageCampaignSinglePropertyTypes';
import {
  DeleteEmailCampaignPayload,
  GetCampaignRecipientsRequestPayload,
  UnScheduleEmailCampaignPayload,
} from '@redux/types/campaigns/manageCampaignTypes';
import { showNotification } from '@shared/components/notification';
import { getDefaultError } from '@shared/utils/common';

const $http = getHTTPClient();

export function* getSinglePropertyCampaignSaga(action: PayloadAction<GetManageSinglePropertyTemplatePayload>) {
  try {
    yield put(manageSinglePropertyCampaignActions.setEmailTemplateLoading(true));

    if (action.payload.campaignId) {
      const { data } = yield call(() => $http.get(`/emails-campaigns/single-property/${action.payload.campaignId}`));

      yield put(manageSinglePropertyCampaignActions.getEmailTemplateSuccess(data));
    } else if (action.payload.disposalId) {
      const { data } = yield call(() =>
        $http.get(`/emails-campaigns/single-property/creating/${action.payload.disposalId}`)
      );

      yield put(manageSinglePropertyCampaignActions.getEmailTemplateSuccess(data.data));
    }
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);
    yield put(manageSinglePropertyCampaignActions.getEmailTemplateFailure(error));
  }
}

export function* getRecipientsSaga(action: PayloadAction<GetCampaignRecipientsRequestPayload>) {
  const source = getCancelRequestSource();
  try {
    yield put(manageSinglePropertyCampaignActions.setRecipientsLoading(true));
    yield put(campaignRecipientsActions.setRecipientsLoading(true));

    action.payload.limit = 1;

    const { data } = yield call(() =>
      $http.post(`/emails-campaigns/contacts`, action.payload, { cancelToken: source.token })
    );

    yield put(manageSinglePropertyCampaignActions.getRecipientsSuccess(data));
    yield put(campaignRecipientsActions.getRecipientsSuccess(data));
  } catch (e) {
    const error = getDefaultError(e?.response?.data?.message);

    yield put(manageSinglePropertyCampaignActions.getRecipientsFailure(error));
    yield put(campaignRecipientsActions.getRecipientsFailure(error));

    showNotification(error, 'error');
  } finally {
    source.cancel();
  }
}

export function* createSinglePropertyCampaignSaga(action: PayloadAction<CreateSinglePropertyCampaignRequestPayload>) {
  try {
    yield put(manageSinglePropertyCampaignActions.setCreateLoading(true));

    yield call(() =>
      $http.post(`/emails-campaigns/single-property/creating/${action.payload.disposalId}`, action.payload.data)
    );

    yield put(manageSinglePropertyCampaignActions.createEmailCampaignSuccess());

    if (action.payload.callback) {
      action.payload.callback();
    }
  } catch (error) {
    if (error?.response?.status == 422) {
      error.errorMessages = {};
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((item: string) => {
          if (Array.isArray(error?.response?.data?.errors[item])) {
            error.errorMessages[item] = error?.response?.data?.errors[item][0];
          } else {
            error.errorMessages[item] = error?.response?.data?.errors[item];
          }
        });
      }

      yield put(manageSinglePropertyCampaignActions.createEmailCampaignFailure(error.errorMessages));
    } else {
      showNotification(getDefaultError(error?.response?.data?.message), 'error');

      yield put(manageSinglePropertyCampaignActions.createEmailCampaignFailure({}));
    }
  }
}

export function* sendTestEmailSaga(action: PayloadAction<SendTestSinglePropertyEmailCampaignPayload>) {
  try {
    yield put(manageSinglePropertyCampaignActions.setSendTestEmailLoading(true));

    yield call(() => $http.post(`/emails-campaigns/single-property/send-test-email`, action.payload));

    yield put(manageSinglePropertyCampaignActions.sendTestEmailSuccess());
  } catch (e) {
    yield put(
      manageSinglePropertyCampaignActions.sendTestEmailFailure(
        getDefaultError(e?.response?.data?.message || e?.message)
      )
    );
  }
}

export function* unScheduleSinglePropertyCampaignSaga(action: PayloadAction<UnScheduleEmailCampaignPayload>) {
  try {
    yield put(manageSinglePropertyCampaignActions.setUnScheduleLoading(true));

    yield call(() => $http.put(`/emails-campaigns/${action.payload.id}/unschedule`));

    yield put(manageSinglePropertyCampaignActions.unScheduleSuccess());

    action.payload.callback();
  } catch (e) {
    yield put(manageSinglePropertyCampaignActions.unScheduleFailure(getDefaultError(e?.response?.data?.message)));
  }
}

export function* scheduleSinglePropertyCampaignSaga(action: PayloadAction<ScheduleSinglePropertyEmailCampaignPayload>) {
  try {
    yield put(manageSinglePropertyCampaignActions.setScheduleLoading(true));

    yield call(() => $http.put(`emails-campaigns/single-property/${action.payload.id}/schedule`, action.payload.data));

    yield put(manageSinglePropertyCampaignActions.scheduleSuccess());
    action.payload.callback();
  } catch (error) {
    if (error?.response?.status == 422) {
      error.errorMessages = {};
      if (error?.response?.data?.errors) {
        Object.keys(error?.response?.data?.errors).forEach((item: string) => {
          if (Array.isArray(error?.response?.data?.errors[item])) {
            error.errorMessages[item] = error?.response?.data?.errors[item][0];
          } else {
            error.errorMessages[item] = error?.response?.data?.errors[item];
          }
        });
      }

      yield put(manageSinglePropertyCampaignActions.scheduleFailure(error.errorMessages));
    } else {
      showNotification(getDefaultError(error?.response?.data?.message), 'error');

      yield put(manageSinglePropertyCampaignActions.scheduleFailure({}));
    }
  }
}

export function* deleteEmailCampaignSaga(action: PayloadAction<DeleteEmailCampaignPayload>) {
  try {
    yield put(manageSinglePropertyCampaignActions.setDeleteLoading(true));

    yield call(() => $http.delete(`/emails-campaigns/${action.payload.id}`));

    yield put(manageSinglePropertyCampaignActions.deleteSuccess());

    action.payload.callback();
  } catch (e) {
    yield put(manageSinglePropertyCampaignActions.deleteFailure(getDefaultError(e?.response?.data?.message)));
  }
}
